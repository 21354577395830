import GenericApi from "../../services/generics";

export const getPricingPackages = async ({ showNew }) => {
  try {
    const responce = await GenericApi.getPricingPackages({ showNew });
    if (!responce.success) {
      throw new Error("Unable to get pricing packages");
    }
    return responce;
  } catch (error) {
    throw error;
  }
};

export const getVipPackages = async ({ page = 0, limit = 25 }) => {
  const response = await GenericApi.getVipRequests({ page, limit });
  return response;
};
