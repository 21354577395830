import React, { useCallback, useEffect, useMemo, useState } from "react";
import classnames from "classnames";
import { isEmpty } from "lodash";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import Config from "../../../../config";

const { OTHER_COUNTRY_CODE } = Config;

function Residency(props) {
  const [cityOptions, setCityOptions] = useState([]);

  const onCityChange = (input) => {
    if (isEmpty(input)) {
      setCityOptions([]);
      return;
    }
    try {
      if (window.google) {
        const Google = window.google;
        const service = new Google.maps.places.AutocompleteService();
        service.getPlacePredictions(
          {
            input: input,
            types: ["geocode"],
            componentRestrictions: {
              country:
                (props.form.residentCountryCode === OTHER_COUNTRY_CODE
                  ? props.form.residentCountryCodeOther
                  : props.form.residentCountryCode) ?? "",
            },
          },
          (predictions, status) => {
            if (status === "OK") {
              const locations = predictions.map(
                (item) => item.structured_formatting.main_text
              );
              setCityOptions(locations);
            }
          }
        );
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  useEffect(() => {
    setCityOptions([]);
  }, [props.form.residentCountryCodeOther, props.form.residentCountryCode]);

  return (
    <div className="form-row m-0">
      <div className="form-group col-md-6 px-sm-2 mb-3">
        <label htmlFor="residentCountryCode">
          Country of Residence <span className="required-tag">*</span>
        </label>
        <select
          className={classnames("form-control", {
            "is-invalid": props.form.residentCountryCodeErr,
          })}
          id="residentCountryCode"
          name="residentCountryCode"
          onChange={props.handleChangeCountry}
          value={props.form.residentCountryCode}
          // disabled="true"
        >
          <option defaultValue hidden>
            -- Select --
          </option>

          {props.filters.countries.map((el, i) => (
            <option value={el.code} key={i}>
              {el.name}
            </option>
          ))}
        </select>
        <div className="invalid-feedback">
          {props.form.residentCountryCodeErr}
        </div>
      </div>

      {props.form.residentCountryCode &&
        props.form.residentCountryCode !== "ZZ" &&
        !isEmpty(props.filters?.regions) && (
          <div className="form-group col-md-6 px-sm-2 mb-3">
            <label htmlFor="residentRegionId">
              State / District <span className="required-tag">*</span>
            </label>
            <select
              className={classnames("form-control", {
                "is-invalid": props.form.residentRegionIdErr,
              })}
              id="residentRegionId"
              name="residentRegionId"
              onChange={props.handleChange}
              value={props.form.residentRegionId}
              disabled={props.filters.regions.length>0 ? false: true}
            >
              <option defaultValue hidden>
                -- Select --
              </option>
              {props.filters.regions.map((el, i) => (
                <option value={el.id} key={i}>
                  {el.regionName}
                </option>
              ))}
            </select>
            <div className="invalid-feedback">
              {props.form.residentRegionIdErr}
            </div>
          </div>
        )}

      {props.form.residentCountryCode &&
        props.form.residentCountryCode === "ZZ" && (
          <div className="form-group col-md-6 px-sm-2 mb-3">
            <label htmlFor="residentCountryCodeOther">
              Select Country <span className="required-tag">*</span>
            </label>
            <select
              className={classnames("form-control", {
                "is-invalid": props.form.residentCountryCodeOtherErr,
              })}
              id="residentCountryCodeOther"
              name="residentCountryCodeOther"
              onChange={props.handleChange}
              value={props.form.residentCountryCodeOther}
            >
              <option defaultValue hidden>
                -- Select --
              </option>
              {props.filters.otherCountries.map((el, i) => (
                <option value={el.code} key={i}>
                  {el.name}
                </option>
              ))}
            </select>
            <div className="invalid-feedback">
              {props.form.residentCountryCodeOtherErr}
            </div>
          </div>
        )}

      <div className="form-group col-md-6 px-sm-2 mb-3">
        <label htmlFor="sizeId">
          City <span className="required-tag">*</span>
        </label>
        <Autocomplete
          className="auto-complete-city"
          options={cityOptions}
          value={props.form.residentCity}
          onChange={(event, newValue) => {
            const e = {
              target: {
                name: "residentCity",
                value: newValue,
              },
            };
            props.handleChange(e);
          }}
          onInputChange={(e) => onCityChange(e?.target?.value ?? "")}
          autoSelect={true}
          freeSolo={true}
          renderInput={(params) => (
            <TextField
              id="residentCity"
              name="residentCity"
              {...params}
              variant="outlined"
            />
          )}
        />
        <p className="cus-error">{props.form.residentCityErr}</p>
      </div>

      {props.form.residentCountryCode !== "LK" && (
        <div className="form-group col-md-6 px-sm-2 mb-3">
          <label htmlFor="visaTypeId">
            Visa Type <span className="required-tag">*</span>
          </label>
          <select
            className={classnames("form-control", {
              "is-invalid": props.form.visaTypeIdErr,
            })}
            id="visaTypeId"
            name="visaTypeId"
            onChange={props.handleChange}
            value={props.form.visaTypeId}
          >
            <option defaultValue hidden>
              -- Select --
            </option>
            {props.filters.visaStatuses.map((el, i) => (
              <option value={el.id} key={i}>
                {el.visaType}
              </option>
            ))}
          </select>
          <div className="invalid-feedback">{props.form.visaTypeIdErr}</div>
        </div>
      )}
    </div>
  );
}

export default Residency;
