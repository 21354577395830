import moment from "moment";
import Config from "../../../../config";
import { USER_PROFILE_STATUS } from "../../../../static-data";

const { NEW_PRICING_RELEASE_DATE, CURRENT_PLATFORM, PLATFORMS } = Config;

export const determinePricingPackages = ({
  adStatus,
  adCreatedDate,
  adFirstPublishDate,
}) => {
  if (CURRENT_PLATFORM === PLATFORMS.IN) {
    return true;
  }

  const adCreatedBeforePricingRelease =
    adCreatedDate && moment(adCreatedDate).isBefore(NEW_PRICING_RELEASE_DATE);
  const adFirstPublishedBeforePricingRelease =
    adFirstPublishDate &&
    moment(adFirstPublishDate).isBefore(NEW_PRICING_RELEASE_DATE);

  return (
    (adStatus === USER_PROFILE_STATUS.PAYMENT_PENDING &&
      adCreatedBeforePricingRelease) ||
    (adStatus === USER_PROFILE_STATUS.IN_REVIEW &&
      adCreatedBeforePricingRelease) ||
    (adStatus === USER_PROFILE_STATUS.LIVE &&
      adFirstPublishedBeforePricingRelease) ||
    (adStatus === USER_PROFILE_STATUS.EXPIRED &&
      adFirstPublishedBeforePricingRelease)
  );
};
