import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import qs from "qs";
import { isEmpty } from "lodash";
import BackBtnHeader from "./components/BackBtnHeader";
import Header from "./components/Header";
import Basic from "./components/Basic";
import Connect from "./components/Connect";
import Personal from "./components/Personal";
import Parent from "./components/Parent";
import Siblings from "./components/Siblings";
import Horoscope from "./components/Horoscope";
import Address from "./components/Address";
import OfflineResponses from "./components/OfflineResponses";
import PaymentHistory from "./components/PaymentHistory";
import TopAddPaymentHistory from "./components/TopAddPaymentHistory";
import PendingEdits from "./components/PendingEdits";
import PendingIdRequest from "./components/PendingIdRequest";
import AcceptedIdRequest from "./components/AcceptedIdRequest";
import Images from "./components/Images";
import NICImages from "./components/NICImages";
import ServiceComments from "./components/ServiceComments";
import Reports from "./components/Reports";
import Stats from "./components/Stats";
import InterestPreference from "./components/InterestPreference";
import Notificationpreference from "./components/Notificationpreference";
import Breadcrumb from "../breadcrumb/Breadcrumb";
import { isAuthenticatedBoth } from "../../../auth/authBoth";
import { isAdminOrOperatorL2 } from "../../../auth/authAdminOrOperatorL2";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import EditAdDetailModal from "../modals/EditAdDetailModal";
import { heightArr } from "../../../constant/heightArr";
import EditInterestPreference from "../interests/EditInterestPreference";
import VerifyMobileModal from "../modals/VerifyMobileModal";
import EditAdCountryModal from "../modals/EditAdCountryModal";
import EditParentsProfession from "../modals/EditParentsProfession";
import ParentCountryModal from "../modals/parentCountryModal";
import ProfilePdfExportModal from "../modals/profilePdfExportModal";
import Config from "../../../config";
import CivilStatusUpdateModal from "../modals/CivilStatusUpdateModal";
import { getUserAccount } from "../../../features/post";
import { PreferredSearchCriteria } from "./components/PreferredSearchCriteria";
import { getPricingPackages } from "../../../features/generic-data";
import { determinePricingPackages } from "./extensions";

function PostCom() {
  const [ad, setAd] = useState({});
  const [account, setAccount] = useState({});
  const [loading, setLoading] = useState(false);
  const [pendingIdRequest, setPendingIdRequest] = useState(false);
  const [acceptedIdRequest, setAcceptedIdRequest] = useState(false);
  const [imagesArray, setImagesArray] = useState([]);
  const [imageUploading, setImageUploading] = useState(false);
  const [horoscopeImageUploading, setHoroscopeImageUploading] = useState(false);
  const [isOverRidePackage, setIsOverRidePackage] = useState(false);
  const [packages, setPackages] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState("");
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [amount, setAmount] = useState(0);
  const [defaultPackage, setDefaultPackage] = useState("");

  // edit post
  const [showEditModal, setShowEditModal] = useState(false);
  const [editTitle, setEditTitle] = useState("");
  const [inputType, setInputType] = useState("");
  const [defaultValue, setDefaultValue] = useState("");
  const [value, setValue] = useState(null);
  const [countryValue, setCountryValue] = useState({});
  const [options, setOptions] = useState([]);
  const [updatingObject, setUpdatingObject] = useState("");
  const [loadingModal, setLoadingModal] = useState(false);
  const [displayTitle, setDisplayTitle] = useState("");
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [showCountryModal, setShowCountryModal] = useState(false);
  const [showParentsProfession, setShowParentsProfession] = useState(false);
  const [phoneID, setPhoneID] = useState(null);
  const [otp, setOtp] = useState(null);
  const [openCivilStatusItemModal, setOpenCivilStatusItemModal] =
    useState(false);
  const drinkingOptions = [
    { type: "Yes", id: "YES" },
    { type: "No", id: "NO" },
    { type: "Occasionally", id: "OCCASIONAL" },
  ];
  const smokingOptions = [
    { type: "Yes", id: "YES" },
    { type: "No", id: "NO" },
  ];
  const horoscopeOptions = [
    { type: "Essential", id: true },
    { type: "Not Essential", id: false },
  ];
  const accountOwnershipOptions = [
    { type: "Self", id: "SELF" },
    { type: "Parents / Family", id: "FAMILY" },
  ];
  const heightOptions = heightArr.map((el) => ({
    type: el.name,
    id: el.value,
  }));
  const [foodPreferenceOptions, setFoodPreferenceOptions] = useState([]);
  const [ethnicityOptions, setEthnicityOptions] = useState([]);
  const [religionOptions, setReligionOptions] = useState([]);
  const [starOptions, setStarOptions] = useState([]);
  const [starSignOptions, setStarSignOptions] = useState([]);
  const [motherTongueOptions, setMotherTongueOptions] = useState([]);
  const [gothraOptions, setGothraOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [visaStatusOptions, setVisaStatusOptions] = useState([]);
  const [detailObj, setDetailObj] = useState("");
  const [civilStatusOptions, setCivilStatusOptions] = useState([]);
  const [educationLevelOptions, setEducationLevelOptions] = useState([]);
  const [professionOptions, setProfessionOptions] = useState([]);
  const [interestPreference, setInterestPreference] = useState(false);
  const [otherProfession, setOtherProfession] = useState(null);
  const [showEditInterest, setShowEditInterest] = useState(false);
  const [paymentHistory, setPaymentHistory] = useState(null);
  const [regions, setRegions] = useState([]);
  const [selectedCountryID, setSelectdCountryID] = useState(false);
  const [otherCountries, setOtherCountries] = useState([]);
  const [countryCode, setCountryCode] = useState("");
  const [region, setRegion] = useState("");
  const [otherCountry, setOtherCountry] = useState("");
  const [city, setCity] = useState("");
  const [visaStatus, setVisaStatus] = useState("");
  const [professionId, setProfessionId] = useState("");
  const [otherTypeProfession, setOtherTypeProfession] = useState("");
  const [dataType, setDataType] = useState("");
  const [parentType, setParentType] = useState("");
  const [otherProfessionKey, setOtherProfessionKey] = useState("");
  const [parentProfession, setParentProfession] = useState("");
  const [parentOtherProfession, setParentOtherProfession] = useState("");
  const [valueExtractor, setValueExtractor] = useState("");
  const [labelExtractor, setLabelExtractor] = useState("");

  const [showParentCountryModal, setShowParentCountryModal] = useState(false);

  const [showProfilePdfExportModal, setShowProfilePdfExportModal] =
    useState(false);

  useEffect(() => {
    handleCountryChange(countryCode);
  }, [countryCode]);

  useEffect(() => {
    if (!isEmpty(ad)) {
      const showNewPricingPackages = determinePricingPackages({
        adStatus: ad?.status,
        adCreatedDate: ad?.createdAt,
        adFirstPublishDate: ad?.firstPublishedAt,
      });
      getPricingPackages({ showNew: !showNewPricingPackages })
        .then((responce) => {
          setPackages(responce.body);
        })
        .catch((err) => {
          console.log("Error >>>>", err);
        });
    }
  }, [ad]);

  const ShowEditInterestSection = () => {
    setShowEditInterest(true);
  };

  const HideEditInterestSection = () => {
    getAd();
    setShowEditInterest(false);
  };

  const closeEditModal = () => {
    setShowEditModal(false);
  };

  const openEditModal = (
    displayTitle,
    title,
    type,
    value,
    selections,
    updatingObject,
    detailObj,
    valueExtractor,
    labelExtractor
  ) => {
    setShowEditModal(true);
    setEditTitle(title);
    setDisplayTitle(displayTitle);
    setInputType(type);
    setDefaultValue(value);
    setValue(value);
    setOptions(selections);
    setUpdatingObject(updatingObject);
    setDetailObj(detailObj);
    setValueExtractor(valueExtractor);
    setLabelExtractor(labelExtractor);
  };

  const openCivilStatusModal = () => {
    setOpenCivilStatusItemModal(true);
  };

  const openCountryDetailsModal = (
    updatingResidentObj,
    valueCountry,
    valueRegion,
    valueCity,
    valueVisaType,
    dataType
  ) => {
    setShowCountryModal(true);
    setCountryValue({
      Country: valueCountry,
      Region: valueRegion ? valueRegion : null,
    });
    setCity(valueCity);
    setVisaStatus(valueVisaType);
    setCountryCode(valueCountry);
    setRegion(valueRegion);
    setDataType(dataType);
  };

  const openParentsProfessionModal = (
    dataType,
    parentType,
    professionId,
    otherProfessionKey,
    professionName,
    otherProfessionName
  ) => {
    setShowParentsProfession(true);
    setDataType(dataType);
    setParentType(parentType);
    setEditTitle(professionId);
    setOtherProfessionKey(otherProfessionKey);
    setUpdatingObject(dataType);
    setDetailObj(parentType);
    setParentProfession(professionName);
    setParentOtherProfession(otherProfessionName);
  };

  const handleCloseCountryModal = () => {
    setShowCountryModal(false);
  };

  const openParentCountryModal = (
    valueCountry,
    detailObj,
    updatingObj,
    dataType
  ) => {
    setShowParentCountryModal(true);
    setCountryValue({
      Country: valueCountry,
      Region: null,
    });
    setDetailObj(detailObj);
    setCountryCode(valueCountry);
    setUpdatingObject(updatingObj);
    setDataType(dataType);
  };

  const handleSave = () => {
    setLoadingModal(true);
    let data = {};
    let subData = {};
    if (updatingObject) {
      if (detailObj === "SELF") {
        subData[editTitle] = value ? value : "";
        if (displayTitle == "Profession") {
          subData["otherProfession"] =
            value === Config.OTHER_PROFESSION_ID ? otherProfession : "";
        }
        data[updatingObject] = subData;
      } else {
        const parentObj = ad.parentInfo.find((el) => el.type === detailObj);
        if (parentObj === undefined) {
          return;
        }
        data[updatingObject] = [
          {
            type: detailObj,
            id: parentObj.id,
            [editTitle]: value,
            [otherProfessionKey]: otherTypeProfession,
          },
        ];
      }
    } else {
      if (editTitle === "differentlyAbled") {
        data[editTitle] = value === "yes";
      } else if (editTitle === "phone") {
        if (Boolean(value)) {
          handlePhoneUpdate(value);
          return;
        } else {
          NotificationManager.error("Please enter phone number", "Error");
          setLoadingModal(false);
          return;
        }
      } else if (editTitle === "email") {
        if (Boolean(value)) {
          updateEmail();
          return;
        } else {
          NotificationManager.error("Please enter email", "Error");
          setLoadingModal(false);
          return;
        }
      } else if (editTitle === "OwnerId") {
        if (Boolean(value)) {
          handleOwnershipSave(value);
          return;
        } else {
          NotificationManager.error("Please Select Account Owner", "Error");
          setLoadingModal(false);
          return;
        }
      } else {
        data[editTitle] = value;
      }
    }

    axios
      .put(`${process.env.REACT_APP_API_URL}/v1/admin/posts/${id}`, data, {
        headers: {
          Authorization: `Bearer ${isAdminOrOperatorL2()}`,
          "content-type": "application/json",
        },
      })
      .then((res) => {
        NotificationManager.success("Post updated", "Success");
        setLoadingModal(false);
        getAd();
        setShowEditModal(false);
        setShowParentsProfession(false);
      })
      .catch((err) => {
        console.log(err);
        NotificationManager.error("Post updating failed", "Error");
        setLoadingModal(false);
        setShowEditModal(false);
        setShowParentsProfession(false);
      });
  };

  const handleOwnershipSave = (value) => {
    const data = qs.stringify({
      accountOwner: value,
    });
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v1/admin/user/${ad.memberId}/account_owner`,
        data,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${isAuthenticatedBoth()}`,
          },
        }
      )
      .then((res) => {
        NotificationManager.success("Post updated", "Success");
        setLoadingModal(false);
        setShowEditModal(false);
        getAd();
      })
      .catch((err) => {
        console.log(err);
        NotificationManager.error("Post updating failed", "Error");
        setLoadingModal(false);
      });
  };

  //get phoneID
  const handlePhoneUpdate = (phone) => {
    if (phone) {
      let data = qs.stringify({
        number: phone,
        channel: "sms",
        webOTP: true,
      });

      let ALPHABET =
        "1ypjZR6TdO8Y9X3IhMe07nWJEKDFftiw24agbBNzGklxCsmZHqucVLUvP5QSro";
      let bb = Math.round(new Date().getTime() / 1000);
      const tso = bb;
      let aa = "";
      while (bb > 0) {
        let tss = ALPHABET[bb % ALPHABET.length].toString();
        aa = aa + tss;
        bb = parseInt(bb / ALPHABET.length);
      }

      axios
        .post(`${process.env.REACT_APP_API_URL}/v1/phonenumbers`, data, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${isAuthenticatedBoth()}`,
            "x-timestamp": tso,
            "x-client-id": aa,
          },
        })
        .then((res) => {
          setPhoneID(res.data.id);
          setLoadingModal(false);
          setShowEditModal(false);
          setShowOtpModal(true);
        })
        .catch((err) => {
          console.log(err);
          setLoadingModal(false);
          NotificationManager.error("Post updating failed", "Error");
        });
    }
  };

  //verify otp
  const verifyOtp = () => {
    setLoadingModal(true);
    if (Boolean(otp)) {
      const data = qs.stringify({
        code: otp,
      });

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/v1/phonenumbers/${phoneID}/verify`,
          data,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              Authorization: `Bearer ${isAuthenticatedBoth()}`,
            },
          }
        )
        .then((res) => {
          updatePhoneNumber();
        })
        .catch((err) => {
          NotificationManager.error("Incorrect OTP code", "Error");
          setLoadingModal(false);
        });
    }
  };

  //update phone number
  const updatePhoneNumber = () => {
    const data = qs.stringify({
      memberId: ad.memberId,
      phoneId: phoneID,
    });

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v1/admin/update_phonenumber`,
        data,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${isAuthenticatedBoth()}`,
          },
        }
      )
      .then((res) => {
        NotificationManager.success("Post updated", "Success");
        setLoadingModal(false);
        setShowOtpModal(false);
        setOtp("");
        getAd();
      })
      .catch((err) => {
        console.log(err);
        NotificationManager.error("Post updating failed", "Error");
        setLoadingModal(false);
        setShowOtpModal(false);
        setOtp("");
      });
  };

  //update email
  const updateEmail = () => {
    const data = qs.stringify({
      memberId: ad.memberId,
      email: value,
    });

    axios
      .post(`${process.env.REACT_APP_API_URL}/v1/admin/update_email`, data, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${isAuthenticatedBoth()}`,
        },
      })
      .then((res) => {
        NotificationManager.success("Post updated", "Success");
        closeEditModal();
        setLoadingModal(false);
        getAd();
      })
      .catch((err) => {
        console.log(err);
        NotificationManager.error("Post updating failed", "Error");
        setLoadingModal(false);
      });
  };

  const handleChange = (e) => {
    if (isNaN(e.target.value) || e.target.value == "") {
      setValue(e.target.value);
      setProfessionId(e.target.value);
      setParentProfession(e.target.value);
    } else {
      setValue(Number(e.target.value));
      setProfessionId(Number(e.target.value));
      setParentProfession(Number(e.target.value));
    }
  };

  const handleCountryChange = async (item) => {
    const otherOptionValue = "ZZ";
    const selectedCountryCode = item;
    setSelectdCountryID(selectedCountryCode !== otherOptionValue);

    let regions = [];
    if (selectedCountryCode !== otherOptionValue) {
      try {
        await axios
          .get(
            `${process.env.REACT_APP_API_URL}/v1/generic/regions?countryCode=${selectedCountryCode}`
          )
          .then((res) => {
            regions = Array.isArray(res.data) ? res.data : [];
            regions = regions.sort((region1, region2) => {
              return region1.regionName.localeCompare(region2.regionName);
            });
            setRegions(regions);
          });
      } catch (e) {
        console.log(e);
      }
    }

    if (selectedCountryID === false) {
      try {
        await axios
          .get(`${process.env.REACT_APP_API_URL}/v1/generic/countries`)
          .then((res) => {
            setOtherCountries(res.data);
          });
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleSaveParentCountryDetails = () => {
    const parentObj = ad.parentInfo.find((obj) => obj.type === detailObj);
    let data = {};

    data[updatingObject] = [
      {
        type: detailObj,
        id: parentObj.id,
        residentCountryCode: countryCode,
      },
    ];

    axios
      .put(`${process.env.REACT_APP_API_URL}/v1/admin/posts/${id}`, data, {
        headers: {
          Authorization: `Bearer ${isAdminOrOperatorL2()}`,
          "content-type": "application/json",
        },
      })
      .then(() => {
        NotificationManager.success("Post updated", "Success");
        getAd();
        setShowParentCountryModal(false);
      })
      .catch(() => {
        NotificationManager.error("Post updating failed", "Error");
        setShowParentCountryModal(false);
      });
  };

  const handleSaveCountryDetails = () => {
    const requestData = {
      residentCountryCode: countryCode,
      residentCountryCodeOther: countryCode === "ZZ" ? otherCountry : null,
      residentRegionId: countryCode !== "ZZ" ? region : null,
      residentCity: city.trim().length > 0 ? city : "",
      visaTypeId: visaStatus,
    };

    const filteredRequestData = Object.fromEntries(
      Object.entries(requestData).filter(([_, value]) => value !== undefined)
    );

    if (Object.keys(filteredRequestData).length === 0) {
      return;
    }

    const data = {
      [dataType]: filteredRequestData,
    };

    axios
      .put(`${process.env.REACT_APP_API_URL}/v1/admin/posts/${id}`, data, {
        headers: {
          Authorization: `Bearer ${isAdminOrOperatorL2()}`,
          "content-type": "application/json",
        },
      })
      .then((res) => {
        NotificationManager.success("Post updated", "Success");
        getAd();
        setShowCountryModal(false);
      })
      .catch((err) => {
        console.log(err);
        NotificationManager.error("Post updating failed", "Error");
        setShowCountryModal(false);
      });
  };

  const handleOtherProfession = (e) => {
    setOtherProfession(e.target.value);
  };

  const querryParams = useLocation().search;
  const id = new URLSearchParams(querryParams).get("id");

  useEffect(() => {
    getAd();
    getPendingPayments();
    getGenericFilters();
    getPaymentHistory();
  }, []);

  const getPaymentHistory = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/v1/admin/posts/${id}/payment_history`,
        {
          headers: {
            Authorization: `Bearer ${isAuthenticatedBoth()}`,
          },
        }
      )
      .then((res) => {
        setPaymentHistory(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getGenericFilters = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/generic/filters`)
      .then((res) => {
        setFoodPreferenceOptions(res.data.foodPreferences);
        setEthnicityOptions(
          res.data.ethnicities.map((el) => ({ type: el.name, id: el.id }))
        );
        setReligionOptions(
          res.data.religions.map((el) => ({ type: el.name, id: el.id }))
        );
        setCivilStatusOptions(
          res.data.civilStatuses.map((el) => ({ type: el.status, id: el.id }))
        );
        setEducationLevelOptions(
          res.data.educationLevels.map((el) => ({ type: el.level, id: el.id }))
        );
        setProfessionOptions(
          res.data.professions.map((el) => ({ type: el.name, id: el.id }))
        );
        setCountryOptions(
          res.data.countries.map((el) => ({ type: el.name, id: el.code }))
        );
        setVisaStatusOptions(
          res.data.visaStatuses.map((el) => ({ type: el.visaType, id: el.id }))
        );
        setStarOptions(
          res.data.stars.map((el) => ({ type: el.name, id: el.id }))
        );
        setStarSignOptions(
          res.data.starSigns.map((el) => ({ type: el.name, id: el.id }))
        );
        setMotherTongueOptions(
          res.data.motherTongues.map((el) => ({ type: el.name, id: el.id }))
        );
        setGothraOptions(
          res.data?.gothras.map((el) => ({ type: el.name, id: el.id }))
        );
      })
      .catch((err) => console.log(err));
  };

  const changeSelectedPackage = (id) => {
    setSelectedPackage(id);
  };

  const changeSelectedCurrency = (id) => {
    setSelectedCurrency(id);
  };

  const changeSelectedAmount = (id) => {
    setAmount(id);
  };

  const getAd = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/admin/posts/${id}`, {
        headers: {
          Authorization: `Bearer ${isAuthenticatedBoth()}`,
        },
      })
      .then((res) => {
        setAd(res.data);
        getAccount(res.data?.memberId);
        if (res.data.interestPreferences) {
          setInterestPreference(true);
        }
        getPendingIdRequests(res.data.memberId);
        setImagesArray(res.data.images);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAccount = (memberId) => {
    if (memberId) {
      getUserAccount({ params: { memberId } })
        .then((res) => {
          setAccount(res.body);
        })
        .catch((error) => {
          console.log("Error", error);
        });
    }
  };

  const getPendingPayments = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/v1/operator_posts/${id}/pending_payments`,
        {
          headers: {
            Authorization: `Bearer ${isAuthenticatedBoth()}`,
          },
        }
      )
      .then((res) => {
        if (res.data && res.data.length > 0) {
          setDefaultPackage(res.data[0].id);
          setSelectedPackage(res.data[0].id);
          setIsOverRidePackage(false);
        } else {
          setDefaultPackage("");
          setSelectedPackage("");
          setIsOverRidePackage(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getPendingIdRequests = (id) => {
    const data = qs.stringify({
      memberId: id,
    });
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/v1/admin/id_verifications?${data}`,
        {
          headers: {
            Authorization: `Bearer ${isAuthenticatedBoth()}`,
          },
        }
      )
      .then((res) => {
        if (res.data.length > 0) {
          res.data.sort((a, b) => {
            let time1 = a.createdAt;
            let time2 = b.createdAt;
            return time2.localeCompare(time1);
          });
          if (res.data[0].status == "PENDING") {
            setPendingIdRequest(res.data[0]);
            setAcceptedIdRequest(false);
          } else if (res.data[0].status == "VERIFIED") {
            setAcceptedIdRequest(res.data[0]);
            setPendingIdRequest(false);
          } else {
            setPendingIdRequest(false);
            setAcceptedIdRequest(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleEditReq = (id, message, value) => {
    setLoading(true);

    const data = qs.stringify({
      approve: value,
      message,
    });
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v1/admin/posts/edit_requests/${id}/approve`,
        data,
        {
          headers: {
            "content-type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${isAuthenticatedBoth()}`,
          },
        }
      )
      .then((res) => {
        setLoading(false);
        if (!value && document.getElementById("editRejectModalClose")) {
          document.getElementById("editRejectModalClose").click();
        }
        if (value && document.getElementById("editReqApproveModalClose")) {
          document.getElementById("editReqApproveModalClose").click();
        }
        getAd();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handlePublish = () => {
    setLoading(true);
    let data = {
      pricingTierOverride: isOverRidePackage,
    };
    if (isOverRidePackage) {
      data["pricingTierId"] = selectedPackage;
      data["paymentCurrency"] = selectedCurrency;
      data["paymentAmount"] = amount;
    }
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v1/admin/posts/${ad.id}/publish`,
        qs.stringify(data),
        {
          headers: {
            Authorization: `Bearer ${isAuthenticatedBoth()}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        getAd();
        setLoading(false);
        document.getElementById("adPublishModalClose").click();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const overRidePackage = (val) => {
    setIsOverRidePackage(val);
  };

  const handleRenew = () => {
    setLoading(true);
    let data = {
      pricingTierOverride: isOverRidePackage,
    };
    if (isOverRidePackage) {
      data["pricingTierId"] = selectedPackage;
      data["paymentCurrency"] = selectedCurrency;
      data["paymentAmount"] = amount;
    }
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v1/admin/posts/${ad.id}/renew`,
        qs.stringify(data),
        {
          headers: {
            Authorization: `Bearer ${isAuthenticatedBoth()}`,
          },
        }
      )
      .then((res) => {
        getAd();
        setLoading(false);
        document.getElementById("adRenewModalClose").click();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleUpload = (e) => {
    setImageUploading(true);
    let newImageName = "";
    let imageFile = new FormData();
    imageFile.append("file", e.target.files[0]);
    var src = URL.createObjectURL(e.target.files[0]);
    axios
      .post(`${process.env.REACT_APP_API_URL}/v1/generic/upload`, imageFile, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${isAuthenticatedBoth()}`,
        },
      })
      .then((res) => {
        newImageName = res.data.name;
        const data = qs.stringify({
          imageId: res.data.name,
        });
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/v1/operator_posts/${ad.id}/images`,
            data,
            {
              headers: {
                Authorization: `Bearer ${isAuthenticatedBoth()}`,
              },
            }
          )
          .then((res) => {
            setImageUploading(false);
            getAd();
          })
          .catch((err) => {
            setImageUploading(false);
          });
      })
      .catch((err) => {
        console.log(err);
        setImageUploading(false);
      });
  };

  const handleUploadHoroscope = (e) => {
    setHoroscopeImageUploading(true);
    let newImageName = "";
    let imageFile = new FormData();
    imageFile.append("file", e.target.files[0]);
    var src = URL.createObjectURL(e.target.files[0]);
    axios
      .post(`${process.env.REACT_APP_API_URL}/v1/generic/upload`, imageFile, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${isAuthenticatedBoth()}`,
        },
      })
      .then((res) => {
        newImageName = res.data.name;
        const data = qs.stringify({
          imageId: res.data.name,
        });
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/v1/operator_posts/${ad.id}/horoscope_images`,
            data,
            {
              headers: {
                Authorization: `Bearer ${isAuthenticatedBoth()}`,
              },
            }
          )
          .then((res) => {
            setHoroscopeImageUploading(false);
            getAd();
          })
          .catch((err) => {
            setHoroscopeImageUploading(false);
          });
      })
      .catch((err) => {
        console.log(err);
        setHoroscopeImageUploading(false);
      });
  };

  const delImages = (id) => {
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/v1/account/posts/${ad.id}/images/${id}`,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${isAuthenticatedBoth()}`,
          },
        }
      )
      .then((res) => {
        getAd();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const delHoroscopeImage = (id) => {
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/v1/account/posts/${ad.id}/horoscope_images/${id}`,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${isAuthenticatedBoth()}`,
          },
        }
      )
      .then((res) => {
        getAd();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteSiblings = async (siblingId) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/v1/account/posts/${ad.id}/siblings/${siblingId}`,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${isAuthenticatedBoth()}`,
          },
        }
      );
      getAd();
    } catch (err) {
      console.log(err);
    }
  };

  const deleteBirthTime = () => {
    const data = { birthTime: "" };
    axios
      .put(`${process.env.REACT_APP_API_URL}/v1/admin/posts/${ad.id}`, data, {
        headers: {
          Authorization: `Bearer ${isAdminOrOperatorL2()}`,
          "content-type": "application/json",
        },
      })
      .then((res) => {
        NotificationManager.success("Birth Time Removed Successfully");
        getAd();
      })
      .catch((err) => {
        NotificationManager.error("Birth Time Removing failed");
      });
  };
  return (
    <div className="content-col" style={{ background: "#ccc" }}>
      <Breadcrumb title={id} />

      <div className="container-fluid inner-content pt-3">
        <div className="cus-container-post p-0">
          <BackBtnHeader
            id={ad.id && ad.id}
            status={ad.status}
            showGetProfilePdfModal={showProfilePdfExportModal}
            setShowPdfModal={setShowProfilePdfExportModal}
          />
          {ad.id && (
            <Header
              ad={ad}
              loading={loading}
              handlePublish={handlePublish}
              handleRenew={handleRenew}
              getAd={getAd}
              isOverRidePackage={isOverRidePackage}
              overRidePackage={overRidePackage}
              packages={packages}
              selectedPackage={selectedPackage}
              selectedCurrency={selectedCurrency}
              amount={amount}
              changeSelectedPackage={changeSelectedPackage}
              changeSelectedAmount={changeSelectedAmount}
              changeSelectedCurrency={changeSelectedCurrency}
              defaultPackage={defaultPackage}
              openEditModal={openEditModal}
            />
          )}
          {ad.id && (
            <Basic
              ad={ad}
              getAd={getAd}
              openEditModal={openEditModal}
              accountOwnershipOptions={accountOwnershipOptions}
            />
          )}
          {ad.id && (
            <Connect
              account={account}
              email={ad?.email}
              phone={ad?.phone}
              openEditModal={openEditModal}
              memberID={ad?.memberId}
              fetchAd={getAd}
              makePhonePublic={ad?.makePhonePublic}
              adId={ad?.id}
            />
          )}
          {ad.id && (
            <Personal
              ad={ad}
              getAd={getAd}
              openEditModal={openEditModal}
              openCountryDetailsModal={openCountryDetailsModal}
              selectedCountryID={selectedCountryID}
              drinkingOptions={drinkingOptions}
              smokingOptions={smokingOptions}
              foodPreferenceOptions={foodPreferenceOptions}
              ethnicityOptions={ethnicityOptions}
              religionOptions={religionOptions}
              civilStatusOptions={civilStatusOptions}
              educationLevelOptions={educationLevelOptions}
              professionOptions={professionOptions}
              heightOptions={heightOptions}
              countryOptions={countryOptions}
              motherTongueOptions={motherTongueOptions}
              gothraOptions={gothraOptions}
              openCivilStatusModal={openCivilStatusModal}
            />
          )}
          {ad.id && (
            <Parent
              ad={ad}
              getAd={getAd}
              openEditModal={openEditModal}
              openParentsProfessionModal={openParentsProfessionModal}
              ethnicityOptions={ethnicityOptions}
              religionOptions={religionOptions}
              professionOptions={professionOptions}
              openCountryDetailsModal={openCountryDetailsModal}
              setShowCountryModal={openParentCountryModal}
              keyExtractor={valueExtractor}
              labelExtractor={labelExtractor}
              countryOptions={countryOptions}
            />
          )}
          {ad.id && (
            <Siblings
              ad={ad}
              getAd={getAd}
              deleteSiblings={deleteSiblings}
              professionOptions={professionOptions}
            />
          )}
          {ad.id && (
            <Horoscope
              ad={ad}
              delImages={delHoroscopeImage}
              handleUpload={handleUploadHoroscope}
              imageUploading={horoscopeImageUploading}
              openEditModal={openEditModal}
              horoscopeOptions={horoscopeOptions}
              handleDelete={deleteBirthTime}
              starOptions={starOptions}
              starSignOptions={starSignOptions}
            />
          )}
          {ad.id && !showEditInterest ? (
            <InterestPreference
              interestPreference={interestPreference}
              residentCountries={
                ad?.interestPreferences?.residentCountries ?? []
              }
              ethnicities={ad?.interestPreferences?.ethnicities ?? []}
              civilStatuses={ad?.interestPreferences?.civilStatuses ?? []}
              minAge={ad?.interestPreferences?.minAge ?? null}
              maxAge={ad?.interestPreferences?.maxAge ?? null}
              heightMin={ad?.interestPreferences?.heightMin ?? null}
              heightMax={ad?.interestPreferences?.heightMax ?? null}
              religions={ad?.interestPreferences?.religions ?? []}
              ShowEditInterestSection={ShowEditInterestSection}
            />
          ) : ad.id && showEditInterest ? (
            <EditInterestPreference
              HideEditInterestSection={HideEditInterestSection}
              interestPreference={ad?.interestPreferences}
              postId={ad?.id}
            />
          ) : null}
          {ad.id && <PreferredSearchCriteria account={account} />}
          {ad.id && ad.pendingEditRequests.length > 0 && (
            <PendingEdits
              ad={ad}
              loading={loading}
              handleEditReq={handleEditReq}
            />
          )}
          {ad.id && pendingIdRequest && (
            <PendingIdRequest
              ad={ad}
              loading={loading}
              request={pendingIdRequest}
              getPendingIdRequests={getPendingIdRequests}
            />
          )}
          {ad.id && acceptedIdRequest && (
            <AcceptedIdRequest
              ad={ad}
              loading={loading}
              request={acceptedIdRequest}
              refreshAd={getAd}
            />
          )}
          {ad.id &&
            !pendingIdRequest &&
            !acceptedIdRequest &&
            isAdminOrOperatorL2() && (
              <NICImages
                getAd={getAd}
                ad={ad}
                imagesArray={imagesArray}
                imageUploading={imageUploading}
                request={acceptedIdRequest}
              />
            )}
          {ad.id && <Address ad={ad} />}
          {ad.id && <Notificationpreference memberID={ad.memberId} />}
          {ad.id && <OfflineResponses ad={ad} />}
          {ad.id && <PaymentHistory payment={paymentHistory?.postPayments} />}
          {ad.id && (
            <TopAddPaymentHistory
              featuredPayment={paymentHistory?.featuredPaymentHistory}
            />
          )}
          {ad.id && isAdminOrOperatorL2() && (
            <Images
              ad={ad}
              imagesArray={imagesArray}
              handleUpload={handleUpload}
              delImages={delImages}
              imageUploading={imageUploading}
            />
          )}
          {ad.id && <Stats ad={ad} />}
          {ad.id && <ServiceComments ad={ad} />}
          {ad.id && <Reports ad={ad} />}
        </div>
      </div>

      <EditAdDetailModal
        showModal={showEditModal}
        closeModal={closeEditModal}
        title={displayTitle}
        inputType={inputType}
        defaultValue={defaultValue}
        value={value}
        options={options}
        handleChange={handleChange}
        handleSave={handleSave}
        loadingModal={loadingModal}
        handleOtherProfession={handleOtherProfession}
        otherProfession={otherProfession}
        valueExtractor={valueExtractor}
        labelExtractor={labelExtractor}
      />

      {showCountryModal && (
        <EditAdCountryModal
          showModal={showCountryModal}
          value={countryValue}
          selectedCountryID={selectedCountryID}
          otherCountries={otherCountries}
          regions={regions}
          handleSave={handleSaveCountryDetails}
          handleCloseCountryModal={handleCloseCountryModal}
          countryOptions={countryOptions}
          visaStatusOptions={visaStatusOptions}
          updateCountry={setCountryCode}
          updateRegion={setRegion}
          updateOtherCountry={setOtherCountry}
          updateCity={setCity}
          updateVisaStatus={setVisaStatus}
          currentCountry={countryCode}
          currentVisaType={visaStatus}
          currentRegion={region}
          currentCity={city}
          currentOtherCountry={otherCountry}
        />
      )}

      <EditParentsProfession
        showModal={showParentsProfession}
        setShowModal={setShowParentsProfession}
        professionOptions={professionOptions}
        updateProfession={setProfessionId}
        parentProfession={parentProfession}
        selectedProfession={professionId}
        updateOtherProfession={setOtherTypeProfession}
        parentOtherProfession={parentOtherProfession}
        handleSave={handleSave}
        handleChange={handleChange}
      />

      <VerifyMobileModal
        showModal={showOtpModal}
        closeModal={setShowOtpModal}
        phoneNo={value}
        loadingModal={loadingModal}
        setOtp={setOtp}
        verifyOtp={verifyOtp}
      />

      <ParentCountryModal
        value={countryValue}
        countryOptions={countryOptions}
        showModal={showParentCountryModal}
        handleClose={setShowParentCountryModal}
        selectedCountryID={selectedCountryID}
        updateCountry={setCountryCode}
        currentCountry={countryCode}
        handleSave={handleSaveParentCountryDetails}
      />

      {openCivilStatusItemModal && (
        <CivilStatusUpdateModal
          civilStatusOptions={civilStatusOptions}
          openModal={openCivilStatusItemModal}
          closeModal={setOpenCivilStatusItemModal}
          advertisement={ad}
          getAdvertisement={getAd}
        />
      )}

      {showProfilePdfExportModal && (
        <ProfilePdfExportModal
          showModal={showProfilePdfExportModal}
          onClose={setShowProfilePdfExportModal}
          adId={ad.id && ad.id}
          hasHoroscopeInfo={ad.hasHoroscopeData && ad.hasHoroscopeData}
        />
      )}

      <NotificationContainer />
    </div>
  );
}

export default PostCom;
