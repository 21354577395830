export const COMMON_SELECTION_OPTIONS = [
  { id: "YES", type: "Yes" },
  { id: "NO", type: "No" },
  {
    id: "UNDISCLOSED",
    type: "Prefer not to mention",
  },
];

export const SUBSCRIPTION_STATUS_INFO = [
  {
    id: "STARTED",
    labelName: "Subscription Active",
    labelType: "success",
  },
  {
    id: "RENEWED",
    labelName: "Subscription Renewed",
    labelType: "success",
  },
  {
    id: "GRACE_PERIOD",
    labelName: "Subscription in Grace Period",
    labelType: "alert",
  },
  {
    id: "RENEW_FAILED",
    labelName: "Subscription Renewal Failed",
    labelType: "alert",
  },
  {
    id: "USER_CANCELLED",
    labelName: "Subscription Cancelled by User",
    labelType: "alert",
  },
  {
    id: "SYSTEM_CANCELLED",
    labelName: "Subscription Cancelled by System",
    labelType: "alert",
  },
];

export const USER_SUBSCRIPTION_STATUS = Object.freeze({
  NOT_STARTED: "NOT_STARTED",
  STARTED: "STARTED",
  RENEWED: "RENEWED",
  GRACE_PERIOD: "GRACE_PERIOD",
  RENEW_FAILED: "RENEW_FAILED",
  USER_CANCELLED: "USER_CANCELLED",
  SYSTEM_CANCELLED: "SYSTEM_CANCELLED",
});

export const USER_PROFILE_STATUS = Object.freeze({
  INCOMPLETE: "INCOMPLETE",
  PAYMENT_PENDING: "PAYMENT_PENDING",
  IN_REVIEW: "IN_REVIEW",
  LIVE: "LIVE",
  EXPIRED: "EXPIRED",
  DISABLED: "DISABLED",
});
